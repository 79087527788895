@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;600;700&display=swap');

:root {
  --color-beige: #f1eeeb;
  --color-blue: #A5ADA6;
  --color-dark-grey: #464646;
}

*,
body,
div {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Rajdhani', sans-serif;
}

h1, h2, h3 {
  font-weight: 600;
  padding-bottom: 1.5rem;
}

p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

/* Carousel */
.each-slide-effect {
  padding: 0 5px 0;
}

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
  border-radius: 0.5rem;
  margin-right: 10px;
}
.each-slide-effect img {
  border-radius: 0.5rem;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.indicator {
  cursor: pointer;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.indicator.active {
  opacity: 1;
}